const readBackgroundURL = (input) => {
  const file = input.files[0];
  const maxSize = 10485760; // 10 MB en octets

  if (file.size > maxSize) {
    alert("La taille du fichier dépasse la limite de 10 MB. Veuillez choisir un autre fichier.");
    input.value = ""; // Réinitialiser l'input
  } else {
    const imagePreview = document.querySelector('#background_img_prev');
    const avatarImage = document.querySelector('#background-remove');
    imagePreview.style.display = "";
    if (document.querySelector('#background-remove')) {
      avatarImage.style.display ="none";
    }
    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        $('#background_img_prev')
          .attr('src', e.target.result);
      };

      reader.readAsDataURL(input.files[0]);
    }
  }
}

export { readBackgroundURL };
